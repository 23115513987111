.contact {
	position: relative;
	padding-top: 50px;
	@include d {
		padding-top: 0; }
	&__bg {
		position: absolute;
		top: 50%;
		left: calc(50% - 650px);
		z-index: -1;
		transform: translateY(-12%);
		@include x {
			left: calc(50% - 600px); }
		@include d {
			left: calc(50% - 520px);
			width: 650px; }
		@include t {
			left: calc(50% - 430px);
			width: 550px; }
		@include m {
			display: none; } }
	&__preview {
		&:nth-child(2) {
			top: -43.5%;
			left: 25%;
			width: 31%; }
		&:nth-child(3) {
			top: -8%;
			left: 1.5%;
			width: 35%;
			&.aos-animate {
				animation: up-down 4s 1.2s infinite; } }
		&:nth-child(4) {
			top: -24%;
			right: 20%;
			width: 42%;
			&.aos-animate {
				animation: up-down 4s 1.8s infinite; } }
		&:nth-child(5) {
			top: 32%;
			left: -8.5%;
			width: 26%;
			@include d {
				display: none; } }
		img {
			width: 100%; }
		@include nf {
			position: absolute; } }
	&__wrap {
		max-width: 405px;
		margin-left: auto;
		@include d {
			max-width: 435px; }
		@include t {
			max-width: 360px; }
		@include m {
			max-width: 100%; } }
	&__title {
		margin-bottom: 12px;
		@include m {
			text-align: center; } }
	&__info {
		margin-bottom: 48px;
		color: $gray;
		@include m {
			margin-bottom: 40px;
			text-align: center; } }
	.field {
		@include nl {
			margin-bottom: 24px; } }
	&__btn {
		@include m {
			min-width: 100%; } }
	@include nl {
		margin-bottom: 136px;
		@include d {
			margin-bottom: 112px; }
		@include m {
			margin-bottom: 48px; } } }
