// media queries
@mixin w {
	@media only screen and (max-width: "1419px") {
		@content; } }
@mixin x {
	@media only screen and (max-width: "1339px") {
		@content; } }
@mixin d {
	@media only screen and (max-width: "1179px") {
		@content; } }
@mixin t {
	@media only screen and (max-width: "1023px") {
		@content; } }
@mixin m {
	@media only screen and (max-width: "767px") {
		@content; } }
@mixin a {
	@media only screen and (max-width: "639px") {
		@content; } }
@mixin s {
	@media only screen and (max-width: "474px") {
		@content; } }

// fonts
@mixin fi {
	font-family: 'Inter', sans-serif; }
@mixin fp {
	font-family: 'Poppins', sans-serif; }

$black: #272E35;
$white: #ffffff;
$gray: #6E757C;
$gray-dark: #6A7986;
$gray-light: #BAC3CA;
$orange: #FAA87A;
$asphalt: #969DB1;
$light: #EEEFF2;
$primary: #FF6E30;
$secondary: #748FB5;
$dark: #1E252B;
$dark-bg: #2C353D;
$dark-light: #74777E;

@mixin d1 {
	@include fp;
	font-size: 72px;
	line-height: (80/72);
	font-weight: 600; }

@mixin d2 {
	@include fp;
	font-size: 64px;
	line-height: (72/64);
	font-weight: 600; }

@mixin h1 {
	@include fp;
	font-size: 56px;
	line-height: (64/56);
	font-weight: 600; }

@mixin h2 {
	@include fp;
	font-size: 48px;
	line-height: (56/48);
	font-weight: 600; }

@mixin h3 {
	@include fp;
	font-size: 40px;
	line-height: (48/40);
	font-weight: 600; }

@mixin h4 {
	@include fp;
	font-size: 32px;
	line-height: (40/32);
	font-weight: 600; }

@mixin h5 {
	@include fp;
	font-size: 24px;
	line-height: (32/24);
	font-weight: 600; }

@mixin h6 {
	@include fp;
	font-size: 20px;
	line-height: (24/20);
	font-weight: 600; }

@mixin sub-headline {
	font-size: 20px;
	font-weight: 400;
	line-height: (24/20); }

@mixin title {
	@include fp;
	font-size: 18px;
	line-height: (24/18);
	font-weight: 600; }

@mixin body-small {
	font-size: 14px;
	line-height: (24/14); }

@mixin button-sm {
	font-size: 14px;
	line-height: (24/14);
	font-weight: 700; }

@mixin caption-13 {
	font-size: 13px;
	line-height: (24/13);
	font-weight: 600; }

@mixin chip {
	font-size: 12px;
	line-height: (16/12);
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: .1em; }

@mixin chip-sm {
	font-size: 11px;
	line-height: (16/11);
	font-weight: 800;
	text-transform: uppercase;
	letter-spacing: .1em; }


